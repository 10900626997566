//! Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//! Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//! Auth const
export const FORGOT_PASSWORD_REQUEST = 'forgot_passord_request';
export const FORGOT_PASSWORD_SUCCESS = 'forgot_passord_success';
export const FORGOT_PASSWORD_FAILURE = 'forgot_passord_failure';
export const SEND_CONTACT_REQUEST = 'send_contact_request';
export const SEND_CONTACT_SUCCESS = 'send_contact_success';
export const SEND_CONTACT_FAILURE = 'send_contact_failure';
export const CHANGE_PASSWORD_REQUEST = 'change_password_request';
export const CHANGE_PASSWORD_SUCCESS = 'change_password_success';
export const CHANGE_PASSWORD_FAILURE = 'change_password_failure';
export const CHANGE_PASSWORD_NO_TOKEN_REQUEST =
  'change_password_no_token_request';
export const CHANGE_PASSWORD_NO_TOKEN_SUCCESS =
  'change_password_no_token_success';
export const CHANGE_PASSWORD_NO_TOKEN_FAILURE =
  'change_password_no_token_failure';

export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const UPDATE_PREFERENCES_DASHBOARD_REQUEST =
  'update_preferences_dashboard_request';
export const UPDATE_PREFERENCES_DASHBOARD_SUCCESS =
  'update_preferences_dashboard_success';
export const UPDATE_PREFERENCES_DASHBOARD_FAILURE =
  'update_preferences_dashboard_failure';

//! Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//! Mail Module const

export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//! TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//! ACTIONS LIVEMAP

export const TOGGLE_TRIPS = 'toggle_trips';
export const PRESELECTIONNED_TRIP = 'preselectionned_trip';

//! ACTIONS VEHICLES

export const GET_ALL_DATA_TO_UPDATE_VEHICLE_REQUEST =
  'get_all_data_to_Update_Vehicle_Request';
export const GET_ALL_DATA_TO_UPDATE_VEHICLE_SUCCESS =
  'get_all_data_to_Update_Vehicle_Success';
export const GET_ALL_DATA_TO_UPDATE_VEHICLE_FAILURE =
  'get_all_data_to_Update_Vehicle_Failure';

export const GET_ALL_DATA_TO_ADD_VEHICLE_REQUEST =
  'get_all_data_to_Add_Vehicle_Request';
export const GET_ALL_DATA_TO_ADD_VEHICLE_SUCCESS =
  'get_all_data_to_Add_Vehicle_Success';
export const GET_ALL_DATA_TO_ADD_VEHICLE_FAILURE =
  'get_all_data_to_Add_Vehicle_Failure';

export const GET_ALL_VEHICLES_REQUEST = 'get_all_vehicle_request';
export const GET_ALL_VEHICLES_SUCCESS = 'get_all_vehicle_success';
export const GET_ALL_VEHICLES_FAILURE = 'get_all_vehicle_failure';
export const UPDATE_VEHICLE_REQUEST = 'update_vehicle_request';
export const UPDATE_VEHICLE_SUCCESS = 'update_vehicle_success';
export const UPDATE_VEHICLE_FAILURE = 'update_vehicle_failure';
export const DELETE_VEHICLE_REQUEST = 'delete_vehicle_request';
export const DELETE_VEHICLE_SUCCESS = 'delete_vehicle_success';
export const DELETE_VEHICLE_FAILURE = 'delete_vehicle_failure';
export const UPDATE_VEHICLE_FORM_MAP_FAILURE =
  'update_vehicle_form_map_failure';
export const UPDATE_VEHICLE_FORM_MAP_REQUEST =
  'update_vehicle_form_map_request';
export const UPDATE_VEHICLE_FORM_MAP_SUCCESS =
  'update_vehicle_form_map_success';
export const GET_SINGLE_VEHICLE_REQUEST = 'get_single_vehicle_request';
export const GET_SINGLE_VEHICLE_SUCCESS = 'get_single_vehicle_success';
export const GET_SINGLE_VEHICLE_FAILURE = 'get_single_vehicle_failure';
export const GET_PRESELECTED_VEHICLES_BARFILTER_REQUEST =
  'get_preselected_vehicles_barfilter_request';
export const GET_PRESELECTED_VEHICLES_BARFILTER_SUCCESS =
  'get_preselected_vehicles_barfilter_success';
export const GET_PRESELECTED_VEHICLES_BARFILTER_FAILURE =
  'get_preselected_vehicles_barfilter_failure';
export const GET_VEHICLES_FILTER_REQUEST = 'get_vehicle_filter_request';
export const GET_VEHICLES_FILTER_SUCCESS = 'get_vehicle_filter_success';
export const GET_VEHICLES_FILTER_FAILURE = 'get_vehicle_filter_failure';
export const GET_VEHICLES_HEADER_REQUEST = 'get_vehicle_header_request';
export const GET_VEHICLES_HEADER_SUCCESS = 'get_vehicle_header_success';
export const GET_VEHICLES_HEADER_FAILURE = 'get_vehicle_header_failure';
export const ADD_VEHICLE_REQUEST = 'add_vehicle_request';
export const ADD_VEHICLE_SUCCESS = 'add_vehicle_success';
export const ADD_VEHICLE_FAILURE = 'add_vehicle_failure';
export const GET_VEHICLE_BRAND_REQUEST = 'get_vehicle_brand_request';
export const GET_VEHICLE_BRAND_SUCCESS = 'get_vehicle_brand_success';
export const GET_VEHICLE_BRAND_FAILURE = 'get_vehicle_brand_failure';
export const GET_VEHICLE_MODEL_REQUEST = 'get_vehicle_model_request';
export const GET_VEHICLE_MODEL_SUCCESS = 'get_vehicle_model_success';
export const GET_VEHICLE_MODEL_FAILURE = 'get_vehicle_model_failure';
export const GET_VEHICLE_TYPE_REQUEST = 'get_vehicle_type_request';
export const GET_VEHICLE_TYPE_SUCCESS = 'get_vehicle_type_success';
export const GET_VEHICLE_TYPE_FAILURE = 'get_vehicle_type_failure';
export const GET_VEHICLE_CATEGORY_REQUEST = 'get_vehicle_category_request';
export const GET_VEHICLE_CATEGORY_SUCCESS = 'get_vehicle_category_success';
export const GET_VEHICLE_CATEGORY_FAILURE = 'get_vehicle_category_failure';
export const GET_TACHY_TYPE_REQUEST = 'get_tachy_type_request';
export const GET_TACHY_TYPE_SUCCESS = 'get_tachy_type_success';
export const GET_TACHY_TYPE_FAILURE = 'get_tachy_type_failure';
export const GET_RDL_TYPE_REQUEST = 'get_rdl_type_request';
export const GET_RDL_TYPE_SUCCESS = 'get_rdl_type_success';
export const GET_RDL_TYPE_FAILURE = 'get_rdl_type_failure';
export const GET_ENGINE_DETECTION_TYPE_REQUEST =
  'get_engine_detection_type_request';
export const GET_ENGINE_DETECTION_TYPE_SUCCESS =
  'get_engine_detection_type_success';
export const GET_ENGINE_DETECTION_TYPE_FAILURE =
  'get_engine_detection_type_failure';
export const GET_ALL_VEHICLE_SITES_FILTER_REQUEST =
  'get_all_vehicle_sites_filter_request';
export const GET_ALL_VEHICLE_SITES_FILTER_SUCCESS =
  'get_all_vehicle_sites_filter_success';
export const GET_ALL_VEHICLE_SITES_FILTER_FAILURE =
  'get_all_vehicle_sites_filter_failure';
export const GET_VEHICLE_GROUPS_FILTER_REQUEST =
  'get_vehicle_groups_filter_request';
export const GET_VEHICLE_GROUPS_FILTER_SUCCESS =
  'get_vehicle_groups_filter_success';
export const GET_VEHICLE_GROUPS_FILTER_FAILURE =
  'get_vehicle_groups_filter_failure';
export const GET_VEHICLE_GROUP_TYPES_REQUEST =
  'get_vehicle_group_types_request';
export const GET_VEHICLE_GROUP_TYPES_SUCCESS =
  'get_vehicle_group_types_success';
export const GET_VEHICLE_GROUP_TYPES_FAILURE =
  'get_vehicle_group_types_failure';
export const GET_VEHICLE_FUEL_REPORT_REQUEST =
  'get_vehicle_fuel_report_request';
export const GET_VEHICLE_FUEL_REPORT_SUCCESS =
  'get_vehicle_fuel_report_success';
export const GET_VEHICLE_FUEL_REPORT_FAILURE =
  'get_vehicle_fuel_report_failure';
export const GET_VEHICLE_FUEL_REPORT_BY_DRIVER_REQUEST =
  'get_vehicle_fuel_report_by_driver_request';
export const GET_VEHICLE_FUEL_REPORT_BY_DRIVER_SUCCESS =
  'get_vehicle_fuel_report_by_driver_success';
export const GET_VEHICLE_FUEL_REPORT_BY_DRIVER_FAILURE =
  'get_vehicle_fuel_report_by_driver_failure';
export const GET_VEHICLE_TMAVA_TOP_REPORT_REQUEST =
  'get_vehicle_tmava_top_report_request';
export const GET_VEHICLE_TMAVA_TOP_REPORT_SUCCESS =
  'get_vehicle_tmava_top_report_success';
export const GET_VEHICLE_TMAVA_TOP_REPORT_FAILURE =
  'get_vehicle_tmava_top_report_failure';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_REQUEST =
  'get_vehicle_fuel_consumption_report_request';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_SUCCESS =
  'get_vehicle_fuel_consumption_report_success';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_FAILURE =
  'get_vehicle_fuel_consumption_report_failure';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST =
  'get_vehicle_fuel_consumption_report_multiple_request';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS =
  'get_vehicle_fuel_consumption_report_multiple_success';
export const GET_VEHICLE_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE =
  'get_vehicle_fuel_consumption_report_multiple_failure';
export const GET_VEHICLE_TOTAL_FUEL_REPORT_REQUEST =
  'get_vehicle_total_fuel_report_request';
export const GET_VEHICLE_TOTAL_FUEL_REPORT_SUCCESS =
  'get_vehicle_total_fuel_report_success';
export const GET_VEHICLE_TOTAL_FUEL_REPORT_FAILURE =
  'get_vehicle_total_fuel_report_failure';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_REQUEST =
  'get_vehicle_total_distance_report_request';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_SUCCESS =
  'get_vehicle_total_distance_report_success';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_FAILURE =
  'get_vehicle_total_distance_report_failure';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST =
  'get_vehicle_total_distance_report_multiple_request';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS =
  'get_vehicle_total_distance_report_multiple_success';
export const GET_VEHICLE_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE =
  'get_vehicle_total_distance_report_multiple_failure';
export const GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST =
  'get_vehicle_total_activity_days_report_request';
export const GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS =
  'get_vehicle_total_activity_days_report_success';
export const GET_VEHICLE_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE =
  'get_vehicle_total_activity_days_report_failure';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST =
  'get_vehicle_activity_stats_report_request';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS =
  'get_vehicle_activity_stats_report_success';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE =
  'get_vehicle_activity_stats_report_failure';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST =
  'get_vehicle_activity_stats_report_multiple_request';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS =
  'get_vehicle_activity_stats_report_multiple_success';
export const GET_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE =
  'get_vehicle_activity_stats_report_multiple_failure';
export const GET_ENUM_CALIB_REQUEST = 'get_enum_calib_request';
export const GET_ENUM_CALIB_SUCCESS = 'get_enum_calib_success';
export const GET_ENUM_CALIB_FAILURE = 'get_enum_calib_failure';
export const GET_VEHICLE_STATUS_REQUEST = 'get_vehicle_status_request';
export const GET_VEHICLE_STATUS_SUCCESS = 'get_vehicle_status_success';
export const GET_VEHICLE_STATUS_FAILURE = 'get_vehicle_status_failure';
export const GET_PTO_TYPE_ENUM_REQUEST = 'get_pto_type_enum_request';
export const GET_PTO_TYPE_ENUM_SUCCESS = 'get_pto_type_enum_success';
export const GET_PTO_TYPE_ENUM_FAILURE = 'get_pto_type_enum_failure';
export const GET_DRIVER_ACTIVITIES_BY_VEHICLE_REQUEST =
  'get_driver_activities_by_vehicle_request';
export const GET_DRIVER_ACTIVITIES_BY_VEHICLE_SUCCESS =
  'get_driver_activities_by_vehicle_success';
export const GET_DRIVER_ACTIVITIES_BY_VEHICLE_FAILURE =
  'get_driver_activities_by_vehicle_failure';
export const GET_VEQ_TMAVA_STATS_REPORT_REQUEST =
  'get_veq_tmava_stats_report_failure';
export const GET_VEQ_TMAVA_STATS_REPORT_SUCCESS =
  'get_veq_tmava_stats_report_success';
export const GET_VEQ_TMAVA_STATS_REPORT_FAILURE =
  'get_veq_tmava_stats_report_failure';
export const GET_ENERGY_ENUM_REQUEST = 'get_energy_enum_request';
export const GET_ENERGY_ENUM_SUCCESS = 'get_energy_enum_success';
export const GET_ENERGY_ENUM_FAILURE = 'get_energy_enum_failure';
export const CHECK_VEHICLE_DATA_IMPORT_REQUEST =
  'check_vehicle_data_import_request';
export const CHECK_VEHICLE_DATA_IMPORT_SUCCESS =
  'check_vehicle_data_import_success';
export const CHECK_VEHICLE_DATA_IMPORT_FAILURE =
  'check_vehicle_data_import_failure';

//! ACTIONS CUSTOMERS

export const GET_CUSTOMERS_REQUEST = 'get_customers_request';
export const GET_CUSTOMERS_SUCCESS = 'get_customers_success';
export const GET_CUSTOMERS_FAILURE = 'get_customers_failure';
export const GET_SINGLE_CUSTOMER_REQUEST = 'get_single_customer_request';
export const GET_SINGLE_CUSTOMER_SUCCESS = 'get_single_customer_success';
export const GET_SINGLE_CUSTOMER_FAILURE = 'get_single_customer_failure';
export const GET_PRESELECTED_CUSTOMER_BARFILTER_REQUEST =
  'get_preselected_customer_barfilter_request';
export const GET_PRESELECTED_CUSTOMER_BARFILTER_SUCCESS =
  'get_preselected_customer_barfilter_success';
export const GET_PRESELECTED_CUSTOMER_BARFILTER_FAILURE =
  'get_preselected_customer_barfilter_failure';
export const GET_CUSTOMERS_FILTER_REQUEST = 'get_customer_filter_request';
export const GET_CUSTOMERS_FILTER_SUCCESS = 'get_customer_filter_success';
export const GET_CUSTOMERS_FILTER_FAILURE = 'get_customer_filter_failure';
export const GET_CUSTOMER_STATUS_LIST_REQUEST =
  'get_customer_status_list_request';
export const GET_CUSTOMER_STATUS_LIST_SUCCESS =
  'get_customer_status_list_success';
export const GET_CUSTOMER_STATUS_LIST_FAILURE =
  'get_customer_status_list_failure';
export const GET_CUSTOMER_STATS_REPORT_REQUEST =
  'get_customer_stats_report_request';
export const GET_CUSTOMER_STATS_REPORT_SUCCESS =
  'get_customer_stats_report_success';
export const GET_CUSTOMER_STATS_REPORT_FAILURE =
  'get_customer_stats_report_failure';
export const UPDATE_CUSTOMER_REQUEST = 'update_site_request';
export const UPDATE_CUSTOMER_SUCCESS = 'update_site_success';
export const UPDATE_CUSTOMER_FAILURE = 'update_site_failure';

//! ACTIONS TANKS

export const ADD_TANK_SERIE_REQUEST = 'add_tank_serie_request';
export const ADD_TANK_SERIE_SUCCESS = 'add_tank_serie_success';
export const ADD_TANK_SERIE_FAILURE = 'add_tank_serie_failure';
export const UPDATE_TANK_SERIE_REQUEST = 'update_tank_serie_request';
export const UPDATE_TANK_SERIE_SUCCESS = 'update_tank_serie_success';
export const UPDATE_TANK_SERIE_FAILURE = 'update_tank_serie_failure';
export const GET_TANK_SERIES_REQUEST = 'get_tank_series_request';
export const GET_TANK_SERIES_SUCCESS = 'get_tank_series_success';
export const GET_TANK_SERIES_FAILURE = 'get_tank_series_failure';
export const GET_SINGLE_TANK_SERIES_REQUEST = 'get_single_tank_series_request';
export const GET_SINGLE_TANK_SERIES_SUCCESS = 'get_single_tank_series_success';
export const GET_SINGLE_TANK_SERIES_FAILURE = 'get_single_tank_series_failure';
export const GET_TANK_SHAPE_TYPES_REQUEST = 'get_tank_shape_types_request';
export const GET_TANK_SHAPE_TYPES_SUCCESS = 'get_tank_shape_types_success';
export const GET_TANK_SHAPE_TYPES_FAILURE = 'get_tank_shape_types_failure';
export const GET_TANK_MATERIAL_TYPES_REQUEST =
  'get_material_shape_types_request';
export const GET_TANK_MATERIAL_TYPES_SUCCESS =
  'get_material_shape_types_success';
export const GET_TANK_MATERIAL_TYPES_FAILURE =
  'get_material_shape_types_failure';
export const GET_TANK_MODELS_REQUEST = 'get_tank_models_request';
export const GET_TANK_MODELS_SUCCESS = 'get_tank_models_success';
export const GET_TANK_MODELS_FAILURE = 'get_tank_models_failure';
export const GET_TANK_BRANDS_REQUEST = 'get_tank_brands_request';
export const GET_TANK_BRANDS_SUCCESS = 'get_tank_brands_success';
export const GET_TANK_BRANDS_FAILURE = 'get_tank_brands_failure';
export const LOAD_SINGLE_TANK_MODELS_REQUEST =
  'load_single_tank_models_request';
export const LOAD_SINGLE_TANK_MODELS_SUCCESS =
  'load_single_tank_models_success';
export const LOAD_SINGLE_TANK_MODELS_FAILURE =
  'load_single_tank_models_failure';
export const CALC_VOLUME_TANK_MODELS_REQUEST =
  'calc_volume_tank_models_request';
export const CALC_VOLUME_TANK_MODELS_SUCCESS =
  'calc_volume_tank_models_success';
export const CALC_VOLUME_TANK_MODELS_FAILURE =
  'calc_volume_tank_models_failure';
export const CALC_VOLUME_TANK_MODELS_AGINSTALLER_REQUEST =
  'calc_volume_tank_models_aginstaller_request';
export const CALC_VOLUME_TANK_MODELS_AGINSTALLER_SUCCESS =
  'calc_volume_tank_models_aginstaller_success';
export const CALC_VOLUME_TANK_MODELS_AGINSTALLER_FAILURE =
  'calc_volume_tank_models_aginstaller_failure';
export const GET_SINGLE_TANK_MODEL_REQUEST = 'get_single_tank_model_request';
export const GET_SINGLE_TANK_MODEL_SUCCESS = 'get_single_tank_model_success';
export const GET_SINGLE_TANK_MODEL_FAILURE = 'get_single_tank_model_failure';
export const GET_TANK_MODELS_FOR_ONE_SERIE_REQUEST =
  'get_tank_models_for_one_serie_request';
export const GET_TANK_MODELS_FOR_ONE_SERIE_SUCCESS =
  'get_tank_models_for_one_serie_success';
export const GET_TANK_MODELS_FOR_ONE_SERIE_FAILURE =
  'get_tank_models_for_one_serie_failure';
export const ADD_TANK_MODEL_REQUEST = 'add_tank_model_request';
export const ADD_TANK_MODEL_SUCCESS = 'add_tank_model_success';
export const ADD_TANK_MODEL_FAILURE = 'add_tank_model_failure';
export const UPDATE_TANK_MODEL_REQUEST = 'update_tank_model_request';
export const UPDATE_TANK_MODEL_SUCCESS = 'update_tank_model_success';
export const UPDATE_TANK_MODEL_FAILURE = 'update_tank_model_failure';
export const DELETE_TANK_MODEL_REQUEST = 'delete_tank_model_request';
export const DELETE_TANK_MODEL_SUCCESS = 'delete_tank_model_success';
export const DELETE_TANK_MODEL_FAILURE = 'delete_tank_model_failure';
export const GET_TANK_CALIBRATIONS_REQUEST = 'get_tank_calibrations_request';
export const GET_TANK_CALIBRATIONS_SUCCESS = 'get_tank_calibrations_success';
export const GET_TANK_CALIBRATIONS_FAILURE = 'get_tank_calibrations_failure';
export const GET_SINGLE_TANK_CALIBRATION_REQUEST =
  'get_single_tank_calibration_request';
export const GET_SINGLE_TANK_CALIBRATION_SUCCESS =
  'get_single_tank_calibration_success';
export const GET_SINGLE_TANK_CALIBRATION_FAILURE =
  'get_single_tank_calibration_failure';
export const GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_REQUEST =
  'get_tank_calibrations_for_one_model_request';
export const GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_SUCCESS =
  'get_tank_calibrations_for_one_model_success';
export const GET_TANK_CALIBRATIONS_FOR_ONE_MODEL_FAILURE =
  'get_tank_calibrations_for_one_model_failure';
export const CALC_VMIN_VMAX_GAUGE_REQUEST = 'calc_vmin_vmax_gauge_request';
export const CALC_VMIN_VMAX_GAUGE_SUCCESS = 'calc_vmin_vmax_gauge_success';
export const CALC_VMIN_VMAX_GAUGE_FAILURE = 'calc_vmin_vmax_gauge_failure';
export const CALC_DEFAULT_VMIN_GAUGE_REQUEST =
  'calc_default_vmin_gauge_request';
export const CALC_DEFAULT_VMIN_GAUGE_SUCCESS =
  'calc_default_vmin_gauge_success';
export const CALC_DEFAULT_VMIN_GAUGE_FAILURE =
  'calc_default_vmin_gauge_failure';
export const ADD_TANK_CALIBRATION_REQUEST = 'add_tank_calibration_request';
export const ADD_TANK_CALIBRATION_SUCCESS = 'add_tank_calibration_success';
export const ADD_TANK_CALIBRATION_FAILURE = 'add_tank_calibration_failure';
export const UPDATE_TANK_CALIBRATION_REQUEST =
  'update_tank_calibration_request';
export const UPDATE_TANK_CALIBRATION_SUCCESS =
  'update_tank_calibration_success';
export const UPDATE_TANK_CALIBRATION_FAILURE =
  'update_tank_calibration_failure';
export const GET_RECOMMENDED_GAUGE_TYPES_REQUEST =
  'get_recommended_gauge_types_request';
export const GET_RECOMMENDED_GAUGE_TYPES_SUCCESS =
  'get_recommended_gauge_types_success';
export const GET_RECOMMENDED_GAUGE_TYPES_FAILURE =
  'get_recommended_gauge_types_failure';
export const GET_SINGLE_RECOMMENDED_GAUGE_TYPE_REQUEST =
  'get_single_recommended_gauge_type_request';
export const GET_SINGLE_RECOMMENDED_GAUGE_TYPE_SUCCESS =
  'get_single_recommended_gauge_type_success';
export const GET_SINGLE_RECOMMENDED_GAUGE_TYPE_FAILURE =
  'get_single_recommended_gauge_type_failure';
export const GET_TCD_FOR_ONE_TANK_CALIBRATION_REQUEST =
  'get_tcd_for_one_tank_calibration_request';
export const GET_TCD_FOR_ONE_TANK_CALIBRATION_SUCCESS =
  'get_tcd_for_one_tank_calibration_success';
export const GET_TCD_FOR_ONE_TANK_CALIBRATION_FAILURE =
  'get_tcd_for_one_tank_calibration_failure';
export const START_CALC_TCD_REQUEST = 'start_calc_tcd_request';
export const START_CALC_TCD_SUCCESS = 'start_calc_tcd_success';
export const START_CALC_TCD_FAILURE = 'start_calc_tcd_failure';
export const GET_GAUGE_TYPES_REQUEST = 'get_gauge_types_request';
export const GET_GAUGE_TYPES_SUCCESS = 'get_gauge_types_success';
export const GET_GAUGE_TYPES_FAILURE = 'get_gauge_types_failuire';

export const GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_REQUEST =
  'get_all_tank_serie_with_preselected_model_request';
export const GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_SUCCESS =
  'get_all_tank_serie_with_preselected_model_success';
export const GET_ALL_TANK_SERIE_WITH_ASSOCIATED_MODELS_FAILURE =
  'get_all_tank_serie_with_preselected_model_failuire';

//! ACTIONS WEBSOCKET

export const WEBSOCKET_CONNECTED = 'websocket_connected';
export const WEBSOCKET_SET_TOKEN_REQUEST = 'websocket_set_token_request';
export const WEBSOCKET_SET_TOKEN_SUCCESS = 'websocket_set_token_success';
export const WEBSOCKET_SET_TOKEN_FAILURE = 'websocket_set_token_failure';
export const WEBSOCKET_SUBSCRIBE_EVENTS_REQUEST =
  'websocket_subscribe_events_request';
export const WEBSOCKET_SUBSCRIBE_EVENTS_SUCCESS =
  'websocket_subscribe_events_success';
export const WEBSOCKET_SUBSCRIBE_EVENTS_FAILURE =
  'websocket_subscribe_events_failure';
export const WEBSOCKET_UNSUBSCRIBE_EVENTS_REQUEST =
  'websocket_unsubscribe_events_request';
export const WEBSOCKET_UNSUBSCRIBE_EVENTS_SUCCESS =
  'websocket_unsubscribe_events_success';
export const WEBSOCKET_UNSUBSCRIBE_EVENTS_FAILURE =
  'websocket_unsubscribe_events_failure';
export const WEBSOCKET_CONNECT_REQUEST = 'websocket_connect_request';
export const WEBSOCKET_CONNECT_SUCCESS = 'websocket_connect_success';
export const WEBSOCKET_CONNECT_FAILURE = 'websocket_connect_failure';
export const WEBSOCKET_NEW_LOCATION = 'websocket_new_location';
export const WEBSOCKET_NEW_ADDRESS = 'websocket_new_address';
export const WEBSOCKET_NEW_DATA = 'websocket_new_data';
export const WEBSOCKET_NEW_DRIVER_DATA = 'websocket_new_driver_data';
export const WEBSOCKET_NEW_ACTIVITY_DATA = 'websocket_new_activity_data';
export const WEBSOCKET_NEW_ALERT_DATA = 'websocket_new_alert_data';

export const WEBSOCKET_10SEC_DATA = 'websocket_10sec_data';

//! ACTIONS DRIVERS

export const GET_ALL_DRIVERS_REQUEST = 'get_all_drivers_request';
export const GET_ALL_DRIVERS_SUCCESS = 'get_all_drivers_success';
export const GET_ALL_DRIVERS_FAILURE = 'get_all_drivers_failure';
export const GET_SINGLE_DRIVER_REQUEST = 'get_single_driver_request';
export const GET_SINGLE_DRIVER_SUCCESS = 'get_single_driver_success';
export const GET_SINGLE_DRIVER_FAILURE = 'get_single_driver_failure';
export const GET_DRIVER_GROUP_REQUEST = 'get_driver_group_request';
export const GET_DRIVER_GROUP_SUCCESS = 'get_driver_group_success';
export const GET_DRIVER_GROUP_FAILURE = 'get_driver_group_failure';
export const ADD_DRIVER_REQUEST = 'add_driver_request';
export const ADD_DRIVER_SUCCESS = 'add_driver_success';
export const ADD_DRIVER_FAILURE = 'add_driver_failure';
export const UPDATE_DRIVER_REQUEST = 'update_driver_request';
export const UPDATE_DRIVER_SUCCESS = 'update_driver_success';
export const UPDATE_DRIVER_FAILURE = 'update_driver_failure';
export const DELETE_DRIVER_REQUEST = 'delete_driver_request';
export const DELETE_DRIVER_SUCCESS = 'delete_driver_success';
export const DELETE_DRIVER_FAILURE = 'delete_driver_failure';
export const INITIAL_FETCH_SINGLE_DRIVER_REQUEST =
  'initial_fetch_single_driver_request';
export const INITIAL_FETCH_SINGLE_DRIVER_SUCCESS =
  'initial_fetch_single_driver_success';
export const INITIAL_FETCH_SINGLE_DRIVER_FAILURE =
  'initial_fetch_single_driver_failure';
export const GET_DRIVER_TMAVA_TOP_REPORT_REQUEST =
  'get_driver_tmava_top_report_request';
export const GET_DRIVER_TMAVA_TOP_REPORT_SUCCESS =
  'get_driver_tmava_top_report_success';
export const GET_DRIVER_TMAVA_TOP_REPORT_FAILURE =
  'get_driver_tmava_top_report_failure';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_REQUEST =
  'get_driver_fuel_consumption_report_request';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_SUCCESS =
  'get_driver_fuel_consumption_report_success';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_FAILURE =
  'get_driver_fuel_consumption_report_failure';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST =
  'get_driver_fuel_consumption_report_multiple_request';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS =
  'get_driver_fuel_consumption_report_multiple_success';
export const GET_DRIVER_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE =
  'get_driver_fuel_consumption_report_multiple_failure';
export const GET_DRIVER_TOTAL_FUEL_REPORT_REQUEST =
  'get_driver_total_fuel_report_request';
export const GET_DRIVER_TOTAL_FUEL_REPORT_SUCCESS =
  'get_driver_total_fuel_report_success';
export const GET_DRIVER_TOTAL_FUEL_REPORT_FAILURE =
  'get_driver_total_fuel_report_failure';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_REQUEST =
  'get_driver_total_distance_report_request';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_SUCCESS =
  'get_driver_total_distance_report_success';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_FAILURE =
  'get_driver_total_distance_report_failure';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST =
  'get_driver_total_distance_report_multiple_request';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS =
  'get_driver_total_distance_report_multiple_success';
export const GET_DRIVER_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE =
  'get_driver_total_distance_report_multiple_failure';
export const GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST =
  'get_driver_total_activity_days_report_request';
export const GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS =
  'get_driver_total_activity_days_report_success';
export const GET_DRIVER_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE =
  'get_driver_total_activity_days_report_failure';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_REQUEST =
  'get_driver_activity_stats_report_request';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS =
  'get_driver_activity_stats_report_success';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_FAILURE =
  'get_driver_activity_stats_report_failure';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST =
  'get_driver_activity_stats_report_multiple_request';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS =
  'get_driver_activity_stats_report_multiple_success';
export const GET_DRIVER_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE =
  'get_driver_activity_stats_report_multiple_failure';
export const GET_DRIVER_ACTIVITIES_BY_DRIVER_REQUEST =
  'get_driver_activities_by_driver_request';
export const GET_DRIVER_ACTIVITIES_BY_DRIVER_SUCCESS =
  'get_driver_activities_by_driver_success';
export const GET_DRIVER_ACTIVITIES_BY_DRIVER_FAILURE =
  'get_driver_activities_by_driver_failure';
export const GET_DRIVER_ACTIVITIES_REQUEST = 'get_driver_activities_request';
export const GET_DRIVER_ACTIVITIES_SUCCESS = 'get_driver_activities_success';
export const GET_DRIVER_ACTIVITIES_FAILURE = 'get_driver_activities_failure';
export const GET_PRESELECTED_DRIVER_BARFILTER_REQUEST =
  'get_preselected_drives_barfilter_request';
export const GET_PRESELECTED_DRIVERS_BARFILTER_SUCCESS =
  'get_preselected_drivers_barfilter_success';
export const GET_PRESELECTED_DRIVER_BARFILTER_FAILURE =
  'get_preselected_drivers_barfilter_failure';
export const GET_DRIVERS_FILTER_REQUEST = 'get_drivers_filter_request';
export const GET_DRIVERS_FILTER_SUCCESS = 'get_drivers_filter_success';
export const GET_DRIVERS_FILTER_FAILURE = 'get_drivers_filter_failure';

//! ACTIONS ZONES
export const GET_ALL_ZONES_REQUEST = 'get_all_zones_request';
export const GET_ALL_ZONES_SUCCESS = 'get_all_zones_success';
export const GET_ALL_ZONES_FAILURE = 'get_all_zones_failure';
export const GET_ZONE_CATEGORY_REQUEST = 'get_zone_category_request';
export const GET_ZONE_CATEGORY_SUCCESS = 'get_zone_category_success';
export const GET_ZONE_CATEGORY_FAILURE = 'get_zone_category_failure';
export const ADD_ZONE_REQUEST = 'add_zone_request';
export const ADD_ZONE_SUCCESS = 'add_zone_success';
export const ADD_ZONE_FAILURE = 'add_zone_failure';
export const DELETE_ZONE_REQUEST = 'delete_zone_request';
export const DELETE_ZONE_SUCCESS = 'delete_zone_success';
export const DELETE_ZONE_FAILURE = 'delete_zone_failure';
export const UPDATE_ZONE_REQUEST = 'update_zone_request';
export const UPDATE_ZONE_SUCCESS = 'update_zone_success';
export const UPDATE_ZONE_FAILURE = 'update_zone_failure';

//! ACTIONS USERS
export const GET_USER_REQUEST = 'get_user_request';
export const GET_USER_SUCCESS = 'get_user_success';
export const GET_USER_FAILURE = 'get_user_failure';
export const CREATE_USER_REQUEST = 'create_user_request';
export const CREATE_USER_SUCCESS = 'create_user_success';
export const CREATE_USER_FAILURE = 'create_user_failure';
export const UPDATE_USER_REQUEST = 'update_user_request';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_USER_FAILURE = 'update_user_failure';
export const DELETE_USER_REQUEST = 'delete_user_request';
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const DELETE_USER_FAILURE = 'delete_user_failure';
export const GET_USERS_LIST_REQUEST = 'get_users_list_request';
export const GET_USERS_LIST_SUCCESS = 'get_users_list_success';
export const GET_USERS_LIST_FAILURE = 'get_users_list_failure';
export const GET_LANGUAGES_ENUM_REQUEST = 'get_languages_enum_request';
export const GET_LANGUAGES_ENUM_SUCCESS = 'get_languages_enum_success';
export const GET_LANGUAGES_ENUM_FAILURE = 'get_languages_enum_failure';
export const GET_DEFAULT_PAGE_ENUM_REQUEST = 'get_default_page_enum_request';
export const GET_DEFAULT_PAGE_ENUM_SUCCESS = 'get_default_page_enum_success';
export const GET_DEFAULT_PAGE_ENUM_FAILURE = 'get_default_page_enum_failure';
export const GET_CURRENCIES_ENUM_REQUEST = 'get_currencies_enum_request';
export const GET_CURRENCIES_ENUM_SUCCESS = 'get_currencies_enum_success';
export const GET_CURRENCIES_ENUM_FAILURE = 'get_currencies_enum_failure';
export const INITIAL_FETCH_ENUM_UPDATE_USER_REQUEST =
  'initial_fetch_enum_update_user_request';
export const INITIAL_FETCH_ENUM_UPDATE_USER_SUCCESS =
  'initial_fetch_enum_update_user_success';
export const INITIAL_FETCH_ENUM_UPDATE_USER_FAILURE =
  'initial_fetch_enum_update_user_failure';
export const GET_CUSTOMER_PROFILES_USER_REQUEST =
  'get_customer_profiles_user_request';
export const GET_CUSTOMER_PROFILES_USER_SUCCESS =
  'get_customer_profiles_user_success';
export const GET_CUSTOMER_PROFILES_USER_FAILURE =
  'get_customer_profiles_user_failure';

//! ACTIONS TRIPS

export const GET_VEHICLE_ACTIVITY_REQUEST = 'get_vehicle_activity_request';
export const GET_VEHICLE_ACTIVITY_SUCCESS = 'get_vehicle_activity_success';
export const GET_VEHICLE_ACTIVITY_FAILURE = 'get_vehicle_activity_failure';
export const GET_VEHICLE_ACTIVITY_DETAILS_REQUEST =
  'get_vehicle_activity_details_request';
export const GET_VEHICLE_ACTIVITY_DETAILS_SUCCESS =
  'get_vehicle_activity_details_success';
export const GET_VEHICLE_ACTIVITY_DETAILS_FAILURE =
  'get_vehicle_activity_details_failure';
export const GET_DRIVER_ACTIVITY_REQUEST = 'get_driver_activity_request';
export const GET_DRIVER_ACTIVITY_SUCCESS = 'get_driver_activity_success';
export const GET_DRIVER_ACTIVITY_FAILURE = 'get_driver_activity_failure';
export const GET_DRIVER_ACTIVITY_DETAILS_REQUEST =
  'get_driver_activity_details_request';
export const GET_DRIVER_ACTIVITY_DETAILS_SUCCESS =
  'get_driver_activity_details_success';
export const GET_DRIVER_ACTIVITY_DETAILS_FAILURE =
  'get_driver_activity_details_failure';

//! ACTIONS ALERTS
export const GET_ALERTS_SINGLE_VEHICLE_REQUEST =
  'get_alerts_single_vehicle_request';
export const GET_ALERTS_SINGLE_VEHICLE_SUCCESS =
  'get_alerts_single_vehicle_success';
export const GET_ALERTS_SINGLE_VEHICLE_FAILURE =
  'get_alerts_single_vehicle_failure';
export const GET_ALERTS_SINGLE_DRIVER_REQUEST =
  'get_alerts_single_driver_request';
export const GET_ALERTS_SINGLE_DRIVER_SUCCESS =
  'get_alerts_single_driver_success';
export const GET_ALERTS_SINGLE_DRIVER_FAILURE =
  'get_alerts_single_driver_failure';
export const GET_ALL_ALERTS_REQUEST = 'get_all_alerts_request';
export const GET_ALL_ALERTS_SUCCESS = 'get_all_alerts_success';
export const GET_ALL_ALERTS_FAILURE = 'get_all_alerts_failure';
export const GET_ALERTS_SINGLE_ZONE_REQUEST = 'get_alerts_single_zone_request';
export const GET_ALERTS_SINGLE_ZONE_SUCCESS = 'get_alerts_single_zone_success';
export const GET_ALERTS_SINGLE_ZONE_FAILURE = 'get_alerts_single_zone_failure';

//! ACTIONS ECO PROFILES
export const GET_ALL_DRIVER_ECO_PROFILES_REQUEST =
  'get_all_driver_eco_profiles_request';
export const GET_ALL_DRIVER_ECO_PROFILES_SUCCESS =
  'get_all_driver_eco_profiles_success';
export const GET_ALL_DRIVER_ECO_PROFILES_FAILURE =
  'get_all_driver_eco_profiles_failure';
export const GET_ALL_VEHICLE_ECO_PROFILES_REQUEST =
  'get_all_vehicle_eco_profiles_request';
export const GET_ALL_VEHICLE_ECO_PROFILES_SUCCESS =
  'get_all_vehicle_eco_profiles_success';
export const GET_ALL_VEHICLE_ECO_PROFILES_FAILURE =
  'get_all_vehicle_eco_profiles_failure';
export const GET_SINGLE_ECO_PROFILE_REQUEST = 'get_single_eco_profile_request';
export const GET_SINGLE_ECO_PROFILE_SUCCESS = 'get_single_eco_profile_success';
export const GET_SINGLE_ECO_PROFILE_FAILURE = 'get_single_eco_profile_failure';
export const GET_SINGLE_VEHICLE_ECO_PROFILE_REQUEST =
  'get_single_vehicle_eco_profile_request';
export const GET_SINGLE_VEHICLE_ECO_PROFILE_SUCCESS =
  'get_single_vehicle_eco_profile_success';
export const GET_SINGLE_VEHICLE_ECO_PROFILE_FAILURE =
  'get_single_vehicle_eco_profile_failure';
export const ADD_ECO_PROFILE_REQUEST = 'add_eco_profile_request';
export const ADD_ECO_PROFILE_SUCCESS = 'add_eco_profile_success';
export const ADD_ECO_PROFILE_FAILURE = 'add_eco_profile_failure';
export const ADD_VEHICLE_ECO_PROFILE_REQUEST =
  'add_vehicle_eco_profile_request';
export const ADD_VEHICLE_ECO_PROFILE_SUCCESS =
  'add_vehicle_eco_profile_success';
export const ADD_VEHICLE_ECO_PROFILE_FAILURE =
  'add_vehicle_eco_profile_failure';
export const UPDATE_ECO_PROFILE_REQUEST = 'update_eco_profile_request';
export const UPDATE_ECO_PROFILE_SUCCESS = 'update_eco_profile_success';
export const UPDATE_ECO_PROFILE_FAILURE = 'update_eco_profile_failure';
export const UPDATE_VEHICLE_ECO_PROFILE_REQUEST =
  'update_vehcile_eco_profile_request';
export const UPDATE_VEHICLE_ECO_PROFILE_SUCCESS =
  'update_vehcile_eco_profile_success';
export const UPDATE_VEHICLE_ECO_PROFILE_FAILURE =
  'update_vehcile_eco_profile_failure';
export const GET_ECO_PROFILE_SCORES_REQUEST = 'get_eco_profile_scores_request';
export const GET_ECO_PROFILE_SCORES_SUCCESS = 'get_eco_profile_scores_success';
export const GET_ECO_PROFILE_SCORES_FAILURE = 'get_eco_profile_scores_failure';
export const GET_VEHICLE_ECO_PROFILE_SCORES_REQUEST =
  'get_vehicle_eco_profile_scores_request';
export const GET_VEHICLE_ECO_PROFILE_SCORES_SUCCESS =
  'get_vehicle_eco_profile_scores_success';
export const GET_VEHICLE_ECO_PROFILE_SCORES_FAILURE =
  'get_vehicle_eco_profile_scores_failure';

//! ACTIONS GLOBAL

export const GLOBAL_FILTER_CHANGE_FIELDS = 'global_filter_change_fields';
export const GLOBAL_FILTER_CHANGE_CUSTOMER = 'global_filter_change_customer';
export const GLOBAL_FILTER_CHANGE_SITES = 'global_filter_change_sites';
export const GLOBAL_FILTER_CHANGE_VEHICLES = 'global_filter_change_vehicles';
export const GLOBAL_FILTER_CHANGE_DRIVERS = 'global_filter_change_drivers';
export const GLOBAL_FILTER_CHANGE_VEHICLE_GROUPS =
  'global_filter_change_vehicle_groups';
export const GLOBAL_FILTER_CHANGE_PERIOD = 'global_filter_change_period';
export const GLOBAL_OPEN_DETAIL_VEHICLE_MODAL =
  'global_open_detail_vehicle_modal';
export const GLOBAL_CLOSE_DETAIL_VEHICLE_MODAL =
  'global_close_detail_vehicle_modal';
export const GLOBAL_OPEN_COMPARE_STAT_MODAL = 'global_open_compare_stat_modal';
export const GLOBAL_CLOSE_COMPARE_STAT_MODAL =
  'global_close_compare_stat_modal';
export const GLOBAL_OPEN_CONSUMPTION_VEHICLE_MODAL =
  'global_open_consumption_vehicle_modal';
export const GLOBAL_CLOSE_CONSUMPTION_VEHICLE_MODAL =
  'global_close_consumption_vehicle_modal';
export const GLOBAL_SEARCH_REQUEST = 'global_search_request';
export const GLOBAL_SEARCH_SUCCESS = 'global_search_success';
export const GLOBAL_SEARCH_FAILURE = 'global_search_failure';
export const GET_CURRENCIES_REQUEST = 'get_currencies_request';
export const GET_CURRENCIES_SUCCESS = 'get_currencies_success';
export const GET_CURRENCIES_FAILURE = 'get_currencies_failure';
export const GET_MEASURE_UNITS_REQUEST = 'get_Measure_units_request';
export const GET_MEASURE_UNITS_SUCCESS = 'get_Measure_units_success';
export const GET_MEASURE_UNITS_FAILURE = 'get_Measure_units_failure';
export const GET_DEFAULT_PAGES_REQUEST = 'get_default_pages_request';
export const GET_DEFAULT_PAGES_SUCCESS = 'get_default_pages_success';
export const GET_DEFAULT_PAGES_FAILURE = 'get_default_pages_failure';
export const GET_FUEL_CONSUMPTION_REPORT_REQUEST =
  'get_fuel_consumption_report_request';
export const GET_FUEL_CONSUMPTION_REPORT_SUCCESS =
  'get_fuel_consumption_report_success';
export const GET_FUEL_CONSUMPTION_REPORT_FAILURE =
  'get_fuel_consumption_report_failure';
export const GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_REQUEST =
  'get_fuel_consumption_report_multiple_request';
export const GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_SUCCESS =
  'get_fuel_consumption_report_multiple_success';
export const GET_FUEL_CONSUMPTION_REPORT_MULTIPLE_FAILURE =
  'get_fuel_consumption_report_multiple_failure';
export const GET_TOTAL_FUEL_REPORT_REQUEST = 'get_total_fuel_report_request';
export const GET_TOTAL_FUEL_REPORT_SUCCESS = 'get_total_fuel_report_success';
export const GET_TOTAL_FUEL_REPORT_FAILURE = 'get_total_fuel_report_failure';
export const GET_TOTAL_DISTANCE_REPORT_REQUEST =
  'get_total_distance_report_request';
export const GET_TOTAL_DISTANCE_REPORT_SUCCESS =
  'get_total_distance_report_success';
export const GET_TOTAL_DISTANCE_REPORT_FAILURE =
  'get_total_distance_report_failure';
export const GET_TOTAL_DISTANCE_REPORT_MULTIPLE_REQUEST =
  'get_total_distance_report_multiple_request';
export const GET_TOTAL_DISTANCE_REPORT_MULTIPLE_SUCCESS =
  'get_total_distance_report_multiple_success';
export const GET_TOTAL_DISTANCE_REPORT_MULTIPLE_FAILURE =
  'get_total_distance_report_multiple_failure';
export const GET_TOTAL_ACTIVITY_DAYS_REPORT_REQUEST =
  'get_total_activity_days_report_request';
export const GET_TOTAL_ACTIVITY_DAYS_REPORT_SUCCESS =
  'get_total_activity_days_report_success';
export const GET_TOTAL_ACTIVITY_DAYS_REPORT_FAILURE =
  'get_total_activity_days_report_failure';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_REQUEST =
  'get_customer_vehicle_activity_stats_report_request';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_SUCCESS =
  'get_customer_vehicle_activity_stats_report_success';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_FAILURE =
  'get_customer_vehicle_activity_stats_report_failure';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_REQUEST =
  'get_customer_vehicle_activity_stats_report_multiple_request';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_SUCCESS =
  'get_customer_vehicle_activity_stats_report_multiple_success';
export const GET_CUSTOMER_VEHICLE_ACTIVITY_STATS_REPORT_MULTIPLE_FAILURE =
  'get_customer_vehicle_activity_stats_report_multiple_failure';
export const GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_REQUEST =
  'get_customer_driver_activity_stats_report_request';
export const GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_SUCCESS =
  'get_customer_driver_activity_stats_report_success';
export const GET_CUSTOMER_DRIVER_ACTIVITY_STATS_REPORT_FAILURE =
  'get_customer_driver_activity_stats_report_failure';
export const GET_PERMANENT_STOCK_RATE_REPORT_REQUEST =
  'get_permanent_stock_rate_report_request';
export const GET_PERMANENT_STOCK_RATE_REPORT_SUCCESS =
  'get_permanent_stock_rate_report_success';
export const GET_PERMANENT_STOCK_RATE_REPORT_FAILURE =
  'get_permanent_stock_rate_report_failure';
export const GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_REQUEST =
  'get_top_lowest_fuel_vehicles_report_request';
export const GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_SUCCESS =
  'get_top_lowest_fuel_vehicles_report_success';
export const GET_TOP_LOWEST_FUEL_VEHICLES_REPORT_FAILURE =
  'get_top_lowest_fuel_vehicles_report_failure';
export const GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_REQUEST =
  'get_top_lowest_battery_vehicles_report_request';
export const GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_SUCCESS =
  'get_top_lowest_battery_vehicles_report_success';
export const GET_TOP_LOWEST_BATTERY_VEHICLES_REPORT_FAILURE =
  'get_top_lowest_battery_vehicles_report_failure';
export const GET_TOP_OVERSPEED_VEHICLES_REPORT_REQUEST =
  'get_top_overspeed_vehicles_report_request';
export const GET_TOP_OVERSPEED_VEHICLES_REPORT_SUCCESS =
  'get_top_overspeed_vehicles_report_success';
export const GET_TOP_OVERSPEED_VEHICLES_REPORT_FAILURE =
  'get_top_overspeed_vehicles_report_failure';
export const GET_VEHICLE_DAILY_REPORT_REQUEST =
  'get_vehicle_daily_report_request';
export const GET_VEHICLE_DAILY_REPORT_SUCCESS =
  'get_vehicle_daily_report_success';
export const GET_VEHICLE_DAILY_REPORT_FAILURE =
  'get_vehicle_daily_report_failure';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_REQUEST =
  'get_vehicle_daily_report_multiple_request';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_SUCCESS =
  'get_vehicle_daily_report_multiple_success';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_FAILURE =
  'get_vehicle_daily_report_multiple_failure';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_REQUEST =
  'get_vehicle_daily_report_multiple_dashboard_trend_request';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_SUCCESS =
  'get_vehicle_daily_report_multiple_dashboard_trend_success';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_DASHBOARD_TREND_FAILURE =
  'get_vehicle_daily_report_multiple_dashboard_trend_failure';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_REQUEST =
  'get_vehicle_daily_report_multiple_for_cumulative_tmava_request';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_SUCCESS =
  'get_vehicle_daily_report_multiple_for_cumulative_tmava_success';
export const GET_VEHICLE_DAILY_REPORT_MULTIPLE_FOR_CUMULATIVE_TMAVA_FAILURE =
  'get_vehicle_daily_report_multiple_for_cumulative_tmava_failure';
export const GET_TOP_VEHICLE_TMAVA_REPORT_REQUEST =
  'get_top_vehicle_tmava_report_request';
export const GET_TOP_VEHICLE_TMAVA_REPORT_SUCCESS =
  'get_top_vehicle_tmava_report_success';
export const GET_TOP_VEHICLE_TMAVA_REPORT_FAILURE =
  'get_top_vehicle_tmava_report_failure';
export const GET_TOP_DRIVER_TMAVA_REPORT_REQUEST =
  'get_top_driver_tmava_report_request';
export const GET_TOP_DRIVER_TMAVA_REPORT_SUCCESS =
  'get_top_driver_tmava_report_success';
export const GET_TOP_DRIVER_TMAVA_REPORT_FAILURE =
  'get_top_driver_tmava_report_failure';
export const GET_TMAVA_REPARTITION_REPORT_REQUEST =
  'get_tmava_repartition_report_request';
export const GET_TMAVA_REPARTITION_REPORT_SUCCESS =
  'get_tmava_repartition_report_success';
export const GET_TMAVA_REPARTITION_REPORT_FAILURE =
  'get_tmava_repartition_report_failure';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REQUEST =
  'get_vehicle_daily_report_by_vehicle_request';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_SUCCESS =
  'get_vehicle_daily_report_by_vehicle_success';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_FAILURE =
  'get_vehicle_daily_report_by_vehicle_failure';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_REQUEST =
  'get_vehicle_daily_report_by_vehicle_reference_request';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_SUCCESS =
  'get_vehicle_daily_report_by_vehicle_reference_success';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_REFERENCE_FAILURE =
  'get_vehicle_daily_report_by_vehicle_reference_failure';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_REQUEST =
  'get_vehicle_daily_report_by_vehicle_multiple_request';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_SUCCESS =
  'get_vehicle_daily_report_by_vehicle_multiple_success';
export const GET_VEHICLE_DAILY_REPORT_BY_VEHICLE_MULTIPLE_FAILURE =
  'get_vehicle_daily_report_by_vehicle_multiple_failure';
export const GET_VEHICLE_LIVE_REPORT_REQUEST =
  'get_vehicle_live_report_request';
export const GET_VEHICLE_LIVE_REPORT_SUCCESS =
  'get_vehicle_live_report_success';
export const GET_VEHICLE_LIVE_REPORT_FAILURE =
  'get_vehicle_live_report_failure';
export const GET_AGSUP_GENERAL_DASHBOARD_REPORT_REQUEST =
  'get_agsup_general_dashboard_report_request';
export const GET_AGSUP_GENERAL_DASHBOARD_REPORT_SUCCESS =
  'get_agsup_general_dashboard_report_success';
export const GET_AGSUP_GENERAL_DASHBOARD_REPORT_FAILURE =
  'get_agsup_general_dashboard_report_failure';
export const GET_AGSUP_GENERAL_DASHBOARD_SITES_REQUEST =
  'get_agsup_general_dashboard_sites_request';
export const GET_AGSUP_GENERAL_DASHBOARD_SITES_SUCCESS =
  'get_agsup_general_dashboard_sites_success';
export const GET_AGSUP_GENERAL_DASHBOARD_SITES_FAILURE =
  'get_agsup_general_dashboard_sites_failure';
export const GET_AGSUP_DASHBOARD_SINGLE_SITE_REQUEST =
  'get_agsup_dashboard_single_site_request';
export const GET_AGSUP_DASHBOARD_SINGLE_SITE_SUCCESS =
  'get_agsup_dashboard_single_site_success';
export const GET_AGSUP_DASHBOARD_SINGLE_SITE_FAILURE =
  'get_agsup_dashboard_single_site_failure';
export const GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_REQUEST =
  'get_agsup_ada_customers_dashboard_report_request';
export const GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_SUCCESS =
  'get_agsup_ada_customers_dashboard_report_success';
export const GET_AGSUP_ADA_CUSTOMERS_DASHBOARD_REPORT_FAILURE =
  'get_agsup_ada_customers_dashboard_report_failure';
export const GET_AGSUP_ADA_CUSTOMER_TREE_REQUEST =
  'get_agsup_ada_customer_tree_request';
export const GET_AGSUP_ADA_CUSTOMER_TREE_SUCCESS =
  'get_agsup_ada_customer_tree_success';
export const GET_AGSUP_ADA_CUSTOMER_TREE_FAILURE =
  'get_agsup_ada_customer_tree_failure';
export const GET_AGSUP_ADA_SINGLE_GROUP_REQUEST =
  'get_agsup_ada_single_group_request';
export const GET_AGSUP_ADA_SINGLE_GROUP_SUCCESS =
  'get_agsup_ada_single_group_success';
export const GET_AGSUP_ADA_SINGLE_GROUP_FAILURE =
  'get_agsup_ada_single_group_failure';
export const GET_REFUEL_VEHICLES_REPORT_REQUEST =
  'get_refuel_vehicles_report_request';
export const GET_REFUEL_VEHICLES_REPORT_SUCCESS =
  'get_refuel_vehicles_report_success';
export const GET_REFUEL_VEHICLES_REPORT_FAILURE =
  'get_refuel_vehicles_report_failure';
export const GET_THEFT_VEHICLES_REPORT_REQUEST =
  'get_theft_vehicles_report_request';
export const GET_THEFT_VEHICLES_REPORT_SUCCESS =
  'get_theft_vehicles_report_success';
export const GET_THEFT_VEHICLES_REPORT_FAILURE =
  'get_theft_vehicles_report_failure';
export const GET_THEFT_REPORT_REQUEST = 'get_theft_report_request';
export const GET_THEFT_REPORT_SUCCESS = 'get_theft_report_success';
export const GET_THEFT_REPORT_FAILURE = 'get_theft_report_failure';
export const GET_DRIVER_DAILY_REPORT_REQUEST =
  'get_driver_daily_report_request';
export const GET_DRIVER_DAILY_REPORT_SUCCESS =
  'get_driver_daily_report_success';
export const GET_DRIVER_DAILY_REPORT_FAILURE =
  'get_driver_daily_report_failure';
export const GET_DRIVER_DAILY_REPORT_MULTIPLE_REQUEST =
  'get_driver_daily_report_multiple_request';
export const GET_DRIVER_DAILY_REPORT_MULTIPLE_SUCCESS =
  'get_driver_daily_report_multiple_success';
export const GET_DRIVER_DAILY_REPORT_MULTIPLE_FAILURE =
  'get_driver_daily_report_multiple_failure';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_REQUEST =
  'get_driver_daily_report_by_driver_request';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_SUCCESS =
  'get_driver_daily_report_by_driver_success';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_FAILURE =
  'get_driver_daily_report_by_driver_failure';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_REQUEST =
  'get_driver_daily_report_by_driver_reference_request';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_SUCCESS =
  'get_driver_daily_report_by_driver_reference_success';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_REFERENCE_FAILURE =
  'get_driver_daily_report_by_driver_reference_failure';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_REQUEST =
  'get_driver_daily_report_by_driver_multiple_request';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_SUCCESS =
  'get_driver_daily_report_by_driver_multiple_success';
export const GET_DRIVER_DAILY_REPORT_BY_DRIVER_MULTIPLE_FAILURE =
  'get_driver_daily_report_by_driver_multiple_failure';
export const GET_TOP_FLOP_REPORT_REQUEST = 'get_top_flop_report_request';
export const GET_TOP_FLOP_REPORT_SUCCESS = 'get_top_flop_report_success';
export const GET_TOP_FLOP_REPORT_FAILURE = 'get_top_flop_report_failure';

export const GET_REFUEL_OPTIMIZATION_REPORT_REQUEST =
  'get_refuel_optimization_report_request';
export const GET_REFUEL_OPTIMIZATION_REPORT_SUCCESS =
  'get_refuel_optimization_report_success';
export const GET_REFUEL_OPTIMIZATION_REPORT_FAILURE =
  'get_refuel_optimization_report_failure';

export const GET_ZONE_STAT_REPORT_REQUEST = 'get_zone_stat_report_request';
export const GET_ZONE_STAT_REPORT_SUCCESS = 'get_zone_stat_report_success';
export const GET_ZONE_STAT_REPORT_FAILURE = 'get_zone_stat_report_failure';

export const GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_REQUEST =
  'get_refuel_optimization_by_vehicle_report_request';
export const GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_SUCCESS =
  'get_refuel_optimization_by_vehicle_report_success';
export const GET_REFUEL_OPTIMIZATION_BY_VEHICLE_REPORT_FAILURE =
  'get_refuel_optimization_by_vehicle_report_failure';

export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_REQUEST =
  'get_average_initial_tank_volume_percent_refuel_report_request';
export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_SUCCESS =
  'get_average_initial_tank_volume_percent_refuel_report_success';
export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_FAILURE =
  'get_average_initial_tank_volume_percent_refuel_report_failure';

export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_REQUEST =
  'get_average_initial_tank_volume_percent_refuel_report_multiple_request';
export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_SUCCESS =
  'get_average_initial_tank_volume_percent_refuel_report_multiple_success';
export const GET_AVERAGE_INITIAL_TANK_VOLUME_PERCENT_REFUEL_REPORT_MULTIPLE_FAILURE =
  'get_average_initial_tank_volume_percent_refuel_report_multiple_failure';

export const GET_MOBILE_OPERATORS_REQUEST = 'get_mobile_operators_request';
export const GET_MOBILE_OPERATORS_SUCCESS = 'get_mobile_operators_success';
export const GET_MOBILE_OPERATORS_FAILURE = 'get_mobile_operators_failure';

export const GET_SIM_DEVICE_TYPE_REQUEST = 'get_sim_device_type_request';
export const GET_SIM_DEVICE_TYPE_SUCCESS = 'get_sim_device_type_success';
export const GET_SIM_DEVICE_TYPE_FAILURE = 'get_sim_device_type_failure';

export const GET_DEVICE_STATUS_REQUEST = 'get_device_status_request';
export const GET_DEVICE_STATUS_SUCCESS = 'get_device_status_success';
export const GET_DEVICE_STATUS_FAILURE = 'get_device_status_failure';

export const GET_DEVICE_TYPE_NAME_REQUEST = 'get_device_type_name_request';
export const GET_DEVICE_TYPE_NAME_SUCCESS = 'get_device_type_name_success';
export const GET_DEVICE_TYPE_NAME_FAILURE = 'get_device_type_name_failure';

export const GET_GROUP_LEVELS_REQUEST = 'get_group_levels_request';
export const GET_GROUP_LEVELS_SUCCESS = 'get_group_levels_success';
export const GET_GROUP_LEVELS_FAILURE = 'get_group_levels_failure';

export const GET_GROUP_LEVELS_FROM_CUSTOMER_REQUEST =
  'get_group_levels_from_customer_request';
export const GET_GROUP_LEVELS_FROM_CUSTOMER_SUCCESS =
  'get_group_levels_from_customer_success';
export const GET_GROUP_LEVELS_FROM_CUSTOMER_FAILURE =
  'get_group_levels_from_customer_failure';

export const GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST =
  'get_treatment_requests_level_enum_request';
export const GET_TREATMENT_REQUESTS_LEVEL_ENUM_SUCCESS =
  'get_treatment_requests_level_enum_success';
export const GET_TREATMENT_REQUESTS_LEVEL_ENUM_FAILURE =
  'get_treatment_requests_level_enum_failure';

export const GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST =
  'get_treatment_requests_type_enum_request';
export const GET_TREATMENT_REQUESTS_TYPE_ENUM_SUCCESS =
  'get_treatment_requests_type_enum_success';
export const GET_TREATMENT_REQUESTS_TYPE_ENUM_FAILURE =
  'get_treatment_requests_type_enum_failure';

export const GET_TREATMENT_REQUESTS_REQUEST = 'get_treatment_requests_request';
export const GET_TREATMENT_REQUESTS_SUCCESS = 'get_treatment_requests_success';
export const GET_TREATMENT_REQUESTS_FAILURE = 'get_treatment_requests_failure';

export const CREATE_TREATMENT_REQUESTS_REQUEST =
  'create_treatment_requests_request';
export const CREATE_TREATMENT_REQUESTS_SUCCESS =
  'create_treatment_requests_success';
export const CREATE_TREATMENT_REQUESTS_FAILURE =
  'create_treatment_requests_failure';

//////////////////////////! GROUPS //////////////////////////////

// Group type
export const GET_GROUP_TYPES_REQUEST = 'get_group_types_request';
export const GET_GROUP_TYPES_SUCCESS = 'get_group_types_success';
export const GET_GROUP_TYPES_FAILURE = 'get_group_types_failure';
export const ADD_GROUP_TYPE_REQUEST = 'add_group_type_request';
export const ADD_GROUP_TYPE_SUCCESS = 'add_group_type_success';
export const ADD_GROUP_TYPE_FAILURE = 'add_group_type_failure';
export const EDIT_GROUP_TYPE_REQUEST = 'edit_group_type_request';
export const EDIT_GROUP_TYPE_SUCCESS = 'edit_group_type_success';
export const EDIT_GROUP_TYPE_FAILURE = 'edit_group_type_failure';
export const DELETE_GROUP_TYPE_REQUEST = 'delete_group_type_request';
export const DELETE_GROUP_TYPE_SUCCESS = 'delete_group_type_success';
export const DELETE_GROUP_TYPE_FAILURE = 'delete_group_type_failure';

// Groups
export const GET_GROUPS_REQUEST = 'get_groups_request';
export const GET_GROUPS_SUCCESS = 'get_groups_success';
export const GET_GROUPS_FAILURE = 'get_groups_failure';
export const GET_GEOGRAPHIC_GROUPS_FILTER_REQUEST =
  'get_geographic_groups_filter_request';
export const GET_GEOGRAPHIC_GROUPS_FILTER_SUCCESS =
  'get_geographic_groups_filter_success';
export const GET_GEOGRAPHIC_GROUPS_FILTER_FAILURE =
  'get_geographic_groups_filter_failure';
export const GET_NO_GEOGRAPHIC_GROUPS_FILTER_REQUEST =
  'get_no_geographic_groups_filter_request';
export const GET_NO_GEOGRAPHIC_GROUPS_FILTER_SUCCESS =
  'get_no_geographic_groups_filter_success';
export const GET_NO_GEOGRAPHIC_GROUPS_FILTER_FAILURE =
  'get_no_geographic_groups_filter_failure';
export const GET_GROUPS_BY_LEVEL_REQUEST = 'get_groups_by_level_request';
export const GET_GROUPS_BY_LEVEL_SUCCESS = 'get_groups_by_level_success';
export const GET_GROUPS_BY_LEVEL_FAILURE = 'get_groups_by_level_failure';
export const GET_DEEPEST_GROUPS_REQUEST = 'get_deepest_groups_request';
export const GET_DEEPEST_GROUPS_SUCCESS = 'get_deepest_groups_success';
export const GET_DEEPEST_GROUPS_FAILURE = 'get_deepest_groups_failure';
export const GET_SINGLE_GROUP_REQUEST = 'get_single_group_request';
export const GET_SINGLE_GROUP_SUCCESS = 'get_single_group_success';
export const GET_SINGLE_GROUP_FAILURE = 'get_single_group_failure';
export const ADD_GROUP_REQUEST = 'add_group_request';
export const ADD_GROUP_SUCCESS = 'add_group_success';
export const ADD_GROUP_FAILURE = 'add_group_failure';
export const EDIT_GROUP_REQUEST = 'edit_group_request';
export const EDIT_GROUP_SUCCESS = 'edit_group_success';
export const EDIT_GROUP_FAILURE = 'edit_group_failure';
export const DELETE_GROUP_REQUEST = 'delete_group_request';
export const DELETE_GROUP_SUCCESS = 'delete_group_success';
export const DELETE_GROUP_FAILURE = 'delete_group_failure';
export const GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST =
  'get_preselected_geographic_groups_barfilter_request';
export const GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS =
  'get_preselected_geographic_groups_barfilter_success';
export const GET_PRESELECTED_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE =
  'get_preselected_geographic_groups_barfilter_failure';
export const GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_REQUEST =
  'get_preselected_no_geographic_groups_barfilter_request';
export const GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_SUCCESS =
  'get_preselected_no_geographic_groups_barfilter_success';
export const GET_PRESELECTED_NO_GEOGRAPHIC_GROUPS_BARFILTER_FAILURE =
  'get_preselected_no_geographic_groups_barfilter_failure';

// Group type and group
export const ADD_GROUP_TYPE_AND_GROUP_REQUEST =
  'add_group_type_and_group_request';
export const ADD_GROUP_TYPE_AND_GROUP_SUCCESS =
  'add_group_type_and_group_success';
export const ADD_GROUP_TYPE_AND_GROUP_FAILURE =
  'add_group_type_and_group_failure';

// Tree area groups
export const GET_TREE_AREA_GROUPS_REQUEST = 'get_tree_area_groups_request';
export const GET_TREE_AREA_GROUPS_SUCCESS = 'get_tree_area_groups_success';
export const GET_TREE_AREA_GROUPS_FAILURE = 'get_tree_area_groups_failure';

// Optional group
export const GET_OPTIONAL_GROUPS_REQUEST = 'get_optional_groups_request';
export const GET_OPTIONAL_GROUPS_SUCCESS = 'get_optional_groups_success';
export const GET_OPTIONAL_GROUPS_FAILURE = 'get_optional_groups_failure';
export const ADD_OPTIONAL_GROUPS_REQUEST = 'add_optional_groups_request';
export const ADD_OPTIONAL_GROUPS_SUCCESS = 'add_optional_groups_success';
export const ADD_OPTIONAL_GROUPS_FAILURE = 'add_optional_groups_failure';
export const DELETE_OPTIONAL_GROUPS_REQUEST = 'delete_optional_groups_request';
export const DELETE_OPTIONAL_GROUPS_SUCCESS = 'delete_optional_groups_success';
export const DELETE_OPTIONAL_GROUPS_FAILURE = 'delete_optional_groups_failure';

//////////////////////////! SIMS //////////////////////////////

export const GET_SIM_LIST_REQUEST = 'get_sim_list_request';
export const GET_SIM_LIST_SUCCESS = 'get_sim_list_success';
export const GET_SIM_LIST_FAILURE = 'get_sim_list_failure';
export const GET_SINGLE_SIM_REQUEST = 'get_single_sim_request';
export const GET_SINGLE_SIM_SUCCESS = 'get_single_sim_success';
export const GET_SINGLE_SIM_FAILURE = 'get_single_sim_failure';
export const ADD_SIM_REQUEST = 'add_sim_request';
export const ADD_SIM_SUCCESS = 'add_sim_success';
export const ADD_SIM_FAILURE = 'add_sim_failure';
export const UPDATE_SIM_REQUEST = 'update_sim_request';
export const UPDATE_SIM_SUCCESS = 'update_sim_success';
export const UPDATE_SIM_FAILURE = 'update_sim_failure';
export const CHECK_SIM_DATA_IMPORT_REQUEST = 'check_sim_data_import_request';
export const CHECK_SIM_DATA_IMPORT_SUCCESS = 'check_sim_data_import_success';
export const CHECK_SIM_DATA_IMPORT_FAILURE = 'check_sim_data_import_failure';

//////////////////////////! PACKAGES //////////////////////////////

export const GET_PACKAGES_LIST_REQUEST = 'get_packages_list_request';
export const GET_PACKAGES_LIST_SUCCESS = 'get_packages_list_success';
export const GET_PACKAGES_LIST_FAILURE = 'get_packages_list_failure';
export const GET_SINGLE_PACKAGE_REQUEST = 'get_single_packages_request';
export const GET_SINGLE_PACKAGE_SUCCESS = 'get_single_packages_success';
export const GET_SINGLE_PACKAGE_FAILURE = 'get_single_packages_failure';
export const GET_PACKAGE_HISTORIC_REQUEST = 'get_package_historic_request';
export const GET_PACKAGE_HISTORIC_SUCCESS = 'get_package_historic_success';
export const GET_PACKAGE_HISTORIC_FAILURE = 'get_package_historic_failure';
export const ADD_PACKAGE_REQUEST = 'add_packages_request';
export const ADD_PACKAGE_SUCCESS = 'add_packages_success';
export const ADD_PACKAGE_FAILURE = 'add_packages_failure';
export const UPDATE_PACKAGE_REQUEST = 'update_packages_request';
export const UPDATE_PACKAGE_SUCCESS = 'update_packages_success';
export const UPDATE_PACKAGE_FAILURE = 'update_packages_failure';
export const INITIAL_FETCH_PACKAGE_REQUEST = 'initial_fetch_request';
export const INITIAL_FETCH_PACKAGE_SUCCESS = 'initial_fetch_success';
export const INITIAL_FETCH_PACKAGE_FAILURE = 'initial_fetch_failure';
export const GET_NO_ASSOCIATED_VEHICLE_REQUEST =
  'get_no_associated_vehicle_request';
export const GET_NO_ASSOCIATED_VEHICLE_SUCCESS =
  'get_no_associated_vehicle_success';
export const GET_NO_ASSOCIATED_VEHICLE_FAILURE =
  'get_no_associated_vehicle_failure';
export const GET_NO_ASSOCIATED_DEVICE_REQUEST =
  'get_no_associated_device_request';
export const GET_NO_ASSOCIATED_DEVICE_SUCCESS =
  'get_no_associated_device_success';
export const GET_NO_ASSOCIATED_DEVICE_FAILURE =
  'get_no_associated_device_failure';
export const GET_NO_ASSOCIATED_SIM_REQUEST = 'get_no_associated_sim_request';
export const GET_NO_ASSOCIATED_SIM_SUCCESS = 'get_no_associated_sim_success';
export const GET_NO_ASSOCIATED_SIM_FAILURE = 'get_no_associated_sim_failure';
export const DELETE_PACKAGE_REQUEST = 'delete_package_request';
export const DELETE_PACKAGE_SUCCESS = 'delete_package_success';
export const DELETE_PACKAGE_FAILURE = 'delete_package_failure';
export const DELETE_SIM_REQUEST = 'delete_sim_request';
export const DELETE_SIM_SUCCESS = 'delete_sim_success';
export const DELETE_SIM_FAILURE = 'delete_sim_failure';
export const DELETE_DEVICE_REQUEST = 'delete_device_request';
export const DELETE_DEVICE_SUCCESS = 'delete_device_success';
export const DELETE_DEVICE_FAILURE = 'delete_device_failure';

//////////////////////////! MODEMS //////////////////////////////

export const GET_MODEM_LIST_REQUEST = 'get_modem_list_request';
export const GET_MODEM_LIST_SUCCESS = 'get_modem_list_success';
export const GET_MODEM_LIST_FAILURE = 'get_modem_list_failure';
export const GET_SINGLE_MODEM_REQUEST = 'get_single_modem_request';
export const GET_SINGLE_MODEM_SUCCESS = 'get_single_modem_success';
export const GET_SINGLE_MODEM_FAILURE = 'get_single_modem_failure';
export const ADD_MODEM_REQUEST = 'add_modem_request';
export const ADD_MODEM_SUCCESS = 'add_modem_success';
export const ADD_MODEM_FAILURE = 'add_modem_failure';
export const UPDATE_MODEM_REQUEST = 'update_modem_request';
export const UPDATE_MODEM_SUCCESS = 'update_modem_success';
export const UPDATE_MODEM_FAILURE = 'update_modem_failure';
export const CHECK_MODEM_DATA_IMPORT_REQUEST =
  'check_modem_data_import_request';
export const CHECK_MODEM_DATA_IMPORT_SUCCESS =
  'check_modem_data_import_success';
export const CHECK_MODEM_DATA_IMPORT_FAILURE =
  'check_modem_data_import_failure';

//////////////////////////! REFUELS //////////////////////////////

export const GET_ALL_REFUELS_REQUEST = 'get_all_refuels_request';
export const GET_ALL_REFUELS_SUCCESS = 'get_all_refuels_success';
export const GET_ALL_REFUELS_FAILURE = 'get_all_refuels_failure';
export const ADD_REFUEL_REQUEST = 'add_refuel_request';
export const ADD_REFUEL_SUCCESS = 'add_refuel_success';
export const ADD_REFUEL_FAILURE = 'add_refuel_failure';
export const DELETE_REFUEL_REQUEST = 'delete_refuel_request';
export const DELETE_REFUEL_SUCCESS = 'delete_refuel_success';
export const DELETE_REFUEL_FAILURE = 'delete_refuel_failure';
export const IMPORT_REFUELS_REQUEST = 'import_refuels_request';
export const IMPORT_REFUELS_SUCCESS = 'import_refuels_success';
export const IMPORT_REFUELS_FAILURE = 'import_refuels_failure';

//////////////////////////! THEFTS //////////////////////////////

export const GET_ALL_THEFTS_REQUEST = 'get_all_thefts_request';
export const GET_ALL_THEFTS_SUCCESS = 'get_all_thefts_success';
export const GET_ALL_THEFTS_FAILURE = 'get_all_thefts_failure';
export const ADD_THEFT_REQUEST = 'add_theft_request';
export const ADD_THEFT_SUCCESS = 'add_theft_success';
export const ADD_THEFT_FAILURE = 'add_theft_failure';
export const DELETE_THEFT_REQUEST = 'delete_theft_request';
export const DELETE_THEFT_SUCCESS = 'delete_theft_success';
export const DELETE_THEFT_FAILURE = 'delete_theft_failure';

//////////////////////////! ADA //////////////////////////////

export const GET_ALL_ADA_REQUEST = 'get_all_ada_request';
export const GET_ALL_ADA_SUCCESS = 'get_all_ada_success';
export const GET_ALL_ADA_FAILURE = 'get_all_ada_failure';
export const GET_ADA_SINGLE_VEHICLE_BY_DATE_REQUEST =
  'get_ada_single_vehicle_by_date_request';
export const GET_ADA_SINGLE_VEHICLE_BY_DATE_SUCCESS =
  'get_ada_single_vehicle_by_date_success';
export const GET_ADA_SINGLE_VEHICLE_BY_DATE_FAILURE =
  'get_ada_single_vehicle_by_date_failure';
export const GET_ADA_TYPE_ENUM_REQUEST = 'get_ada_type_enum_request';
export const GET_ADA_TYPE_ENUM_SUCCESS = 'get_ada_type_enum_success';
export const GET_ADA_TYPE_ENUM_FAILURE = 'get_ada_type_enum_failure';
export const UPDATE_ADA_REQUEST = 'update_ada_request';
export const UPDATE_ADA_SUCCESS = 'update_ada_success';
export const UPDATE_ADA_FAILURE = 'update_ada_failure';
export const CREATE_ADA_REQUEST = 'create_ada_request';
export const CREATE_ADA_SUCCESS = 'create_ada_success';
export const CREATE_ADA_FAILURE = 'create_ada_failure';
export const GET_SAV_COUNT_BY_ADA_REQUEST = 'get_sav_count_by_ada_request';
export const GET_SAV_COUNT_BY_ADA_SUCCESS = 'get_sav_count_by_ada_success';
export const GET_SAV_COUNT_BY_ADA_FAILURE = 'get_sav_count_by_ada_failure';

//////////////////////////! VEHICLE DAILY REPORTS //////////////////////////////

export const GET_ALL_VEHICLE_DAILY_REPORTS_REQUEST =
  'get_all_vehicle_daily_reports_request';
export const GET_ALL_VEHICLE_DAILY_REPORTS_SUCCESS =
  'get_all_vehicle_daily_reports_success';
export const GET_ALL_VEHICLE_DAILY_REPORTS_FAILURE =
  'get_all_vehicle_daily_reports_failure';
export const SWITCH_REF_CONSUMPTION_REQUEST = 'switch_ref_consumption_request';
export const SWITCH_REF_CONSUMPTION_SUCCESS = 'switch_ref_consumption_success';
export const SWITCH_REF_CONSUMPTION_FAILURE = 'switch_ref_consumption_failure';

//////////////////////////! STABILITY POINTS //////////////////////////////

export const GET_STABILITY_POINTS_REQUEST = 'get_stability_points_request';
export const GET_STABILITY_POINTS_SUCCESS = 'get_stability_points_success';
export const GET_STABILITY_POINTS_FAILURE = 'get_stability_points_failure';
