import React, { Component } from 'react';
import {
  ReferenceLine,
  ReferenceArea,
  Bar,
  CartesianGrid,
  ComposedChart,
  Area,
  Line,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
//import data from './data-linechart';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

class SimpleComposedChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherReferenceArea: null,
      legendsHidden: {}
    };
  }

  onLegendClick = dataKey => {
    this.state.legendsHidden[dataKey.id] = !this.state.legendsHidden[
      dataKey.id
    ];
    this.setState({
      legendsHidden: this.state.legendsHidden
    });
  };

  renderDefaultCharts = () => {
    let res = [];
    res.push(
      <Area type="monotone" dataKey="amt" fill="#FFC71F" stroke="#FFC71F" />
    );
    res.push(<Bar dataKey="pv" barSize={20} fill="#FFC207" />);
    res.push(<Line type="monotone" dataKey="uv" stroke="#55D8FE" />);
    return res;
  };

  showSelectedDeviceDataRect = (firstPosition, lastPosition) => {
    this.setState({
      otherReferenceArea: { start: firstPosition, end: lastPosition }
    });
  };

  componentDidMount() {
    if (this.props.onRef) this.props.onRef(this);
  }
  componentWillUnmount() {
    if (this.props.onRef) this.props.onRef(undefined);
  }

  render() {
    return (
      <ResponsiveContainer
        id={
          this.props.chartForWidget
            ? this.props.chartForWidget
            : 'chart-container'
        }
        width="100%"
        height={this.props.height ? this.props.height : '100%'}>
        <ComposedChart
          syncId={this.props.syncId}
          onClick={this.props.onClick ? e => this.props.onClick(e) : null}
          data={this.props.data ? this.props.data : data}
          margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <XAxis
            dataKey="name"
            tickFormatter={
              this.props.formatXAxis ? this.props.formatXAxis : null
            }
            ticks={this.props.ticks ? this.props.ticks : null}
          />
          <YAxis
            width={this.props.chartForWidget === 'cumulative-tmava' ? 40 : 60}
          />
          {this.props.customTooTip ? (
            <Tooltip
              wrapperStyle={{ top: -150, left: 200, zIndex: 10000 }}
              content={this.props.customTooTip}
            />
          ) : (
            <Tooltip />
          )}
          {this.props.legend && this.props.dataKeys ? (
            this.props.verticalLegend ? (
              <Legend
                onClick={this.onLegendClick}
                payload={this.props.dataKeys.map(dataKey => {
                  return {
                    id: dataKey.key,
                    value: dataKey.key,
                    type: 'square',
                    color: this.state.legendsHidden[dataKey.key]
                      ? '#607D8B'
                      : dataKey.stroke
                  };
                })}
                iconType="square"
                layout="vertical"
                verticalAlign="middle"
                align={'right'}
                wrapperStyle={{ right: 0 }}
              />
            ) : (
              <Legend
                onClick={this.onLegendClick}
                payload={this.props.dataKeys.map(dataKey => {
                  return {
                    id: dataKey.key,
                    value: dataKey.key,
                    type: 'square',
                    color: this.state.legendsHidden[dataKey.key]
                      ? '#607D8B'
                      : dataKey.stroke
                  };
                })}
                layout="horizontal"
                align="center"
                verticalAlign="bottom"
              />
            )
          ) : null}
          <CartesianGrid stroke="#f5f5f5" />

          {this.props.dataKeys
            ? this.props.dataKeys.map((dataKey, index) => {
                if (!this.state.legendsHidden[dataKey.key]) {
                  switch (dataKey.chart) {
                    case 'Area':
                      return (
                        <Area
                          type="monotone"
                          key={dataKey.key + index}
                          dataKey={dataKey.key}
                          stackId="1"
                          stroke={dataKey.stroke ? dataKey.stroke : '#FFC71F'}
                          fill={dataKey.stroke ? dataKey.stroke : '#FFC71F'}
                        />
                      );
                    case 'Bar':
                      return (
                        <Bar
                          key={dataKey.key + index}
                          dataKey={dataKey.key}
                          fill="#FFC207"
                          barSize={15}
                        />
                      );
                    case 'Line':
                      return (
                        <Line
                          key={dataKey.key + index}
                          dot={false}
                          type="monotone"
                          dataKey={dataKey.key}
                          stroke={dataKey.stroke ? dataKey.stroke : '#55D8FE'}
                          strokeWidth={2}
                        />
                      );
                  }
                }
              })
            : this.renderDefaultCharts()}

          {/* {this.props.legend ? 
          this.props.verticalLegend ?
            <Legend iconType="square" layout="vertical" align="right" verticalAlign="middle"/>
          :
            <Legend iconType="square" layout="horizontal" align="center" verticalAlign="bottom"/> 
        : null} */}

          {this.props.refs
            ? this.props.refs.map((ref, index) => {
                if (ref.start && ref.end)
                  return (
                    <ReferenceArea
                      key={index}
                      x1={ref.start}
                      x2={ref.end}
                      stroke={ref.color ? ref.color : '#87C443'}
                      fill={ref.color ? ref.color : '#87C443'}
                      fillStroke={0.5}
                      fillOpacity={0.01}
                      ifOverflow="visible"
                    />
                  );
              })
            : null}

          {this.state.otherReferenceArea &&
          this.state.otherReferenceArea.start ? (
            this.state.otherReferenceArea.end ? (
              <ReferenceArea
                x1={this.state.otherReferenceArea.start}
                x2={this.state.otherReferenceArea.end}
                stroke="#87C443"
                fill="#87C443"
                fillStroke={0.5}
                fillOpacity={0.01}
                ifOverflow="visible"
                strokeDasharray="3 3"
              />
            ) : (
              <ReferenceLine
                x={this.state.otherReferenceArea.start}
                stroke="#87C443"
                strokeDasharray="3 3"
              />
            )
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default SimpleComposedChart;
