import {
  SHOW_MESSAGE,
  GLOBAL_FILTER_CHANGE_FIELDS,
  GLOBAL_FILTER_CHANGE_CUSTOMER,
  GLOBAL_FILTER_CHANGE_SITES,
  GLOBAL_FILTER_CHANGE_VEHICLES,
  GLOBAL_FILTER_CHANGE_VEHICLE_GROUPS,
  GLOBAL_FILTER_CHANGE_DRIVERS,
  GLOBAL_FILTER_CHANGE_PERIOD,
  GLOBAL_OPEN_DETAIL_VEHICLE_MODAL,
  GLOBAL_CLOSE_DETAIL_VEHICLE_MODAL,
  GLOBAL_OPEN_COMPARE_STAT_MODAL,
  GLOBAL_CLOSE_COMPARE_STAT_MODAL,
  GLOBAL_OPEN_CONSUMPTION_VEHICLE_MODAL,
  GLOBAL_CLOSE_CONSUMPTION_VEHICLE_MODAL,
  GLOBAL_SEARCH_REQUEST,
  GET_CURRENCIES_REQUEST,
  GET_MEASURE_UNITS_REQUEST,
  GET_DEFAULT_PAGES_REQUEST,
  GET_TREATMENT_REQUESTS_REQUEST,
  GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST,
  GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST,
  CREATE_TREATMENT_REQUESTS_REQUEST,
} from 'constants/ActionTypes';

export const showMessage = ({
  title,
  message,
  messageType,
  duration,
  position,
}) => {
  return {
    type: SHOW_MESSAGE,
    title,
    message,
    messageType,
    duration,
    position,
  };
};

export const hideMessage = () => {};

export const changeFilterSelectedFields = ({
  customer,
  sites,
  vehicles,
  vehicleGroups,
  period,
  drivers,
  startDate,
  endDate,
  adaType,
  adaOccurence,
  calibrationType,
  packageActive,
}) => {
  return {
    type: GLOBAL_FILTER_CHANGE_FIELDS,
    payload: {
      customer,
      sites,
      vehicles,
      vehicleGroups,
      period,
      drivers,
      startDate,
      endDate,
      adaType,
      adaOccurence,
      calibrationType,
      packageActive,
    },
  };
};

export const changeAdaFilterSelectedFields = ({
  customer,
  sites,
  vehicles,
  vehicleGroups,
  adaType,
  startDate,
  endDate,
}) => {
  return {
    type: GLOBAL_FILTER_CHANGE_FIELDS,
    payload: {
      customer,
      sites,
      vehicles,
      vehicleGroups,
      adaType,
      startDate,
      endDate,
    },
  };
};

export const changeFilterSelectedCustomer = ({ customer }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_CUSTOMER,
    payload: customer,
  };
};

export const changeFilterSelectedSites = ({ sites }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_SITES,
    payload: sites,
  };
};

export const changeFilterSelectedVehicles = ({ vehicles }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_VEHICLES,
    payload: vehicles,
  };
};

export const changeFilterSelectedDrivers = ({ drivers }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_DRIVERS,
    payload: drivers,
  };
};

export const changeFilterSelectedVehicleGroups = ({ vehicleGroups }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_VEHICLE_GROUPS,
    payload: vehicleGroups,
  };
};

export const changeFilterSelectedPeriod = ({ period }) => {
  return {
    type: GLOBAL_FILTER_CHANGE_PERIOD,
    payload: period,
  };
};

export const openDetailVehicleModal = ({ vehicle }) => {
  return {
    type: GLOBAL_OPEN_DETAIL_VEHICLE_MODAL,
    payload: vehicle,
  };
};

export const closeDetailVehicleModal = () => {
  return {
    type: GLOBAL_CLOSE_DETAIL_VEHICLE_MODAL,
  };
};

export const openCompareStatModal = ({
  widget,
  attributes,
  request,
  reducer,
  dataFormater,
  graphDataFormater,
  printedValue,
  columns,
}) => {
  return {
    type: GLOBAL_OPEN_COMPARE_STAT_MODAL,
    payload: {
      widget,
      attributes,
      request,
      reducer,
      dataFormater,
      graphDataFormater,
      printedValue,
      columns,
    },
  };
};

export const closeCompareStatModal = () => {
  return {
    type: GLOBAL_CLOSE_COMPARE_STAT_MODAL,
  };
};

export const openConsumptionVehicleModal = ({ vehicle, driver, period }) => {
  return {
    type: GLOBAL_OPEN_CONSUMPTION_VEHICLE_MODAL,
    payload: { vehicle, driver, period },
  };
};

export const closeConsumptionVehicleModal = () => {
  return {
    type: GLOBAL_CLOSE_CONSUMPTION_VEHICLE_MODAL,
  };
};

export const globalSearch = ({ customerId, offset, limit, pattern }) => {
  return {
    type: GLOBAL_SEARCH_REQUEST,
    payload: { customerId, offset, limit, pattern },
  };
};

export const getCurrencies = () => {
  return {
    type: GET_CURRENCIES_REQUEST,
  };
};

export const getMeasureUnits = () => {
  return {
    type: GET_MEASURE_UNITS_REQUEST,
  };
};

export const getDefaultPages = () => {
  return {
    type: GET_DEFAULT_PAGES_REQUEST,
  };
};

export const getTreatmentRequests = () => {
  return {
    type: GET_TREATMENT_REQUESTS_REQUEST,
  };
};

export const getTreatmentRequestsLevelEnum = () => {
  return {
    type: GET_TREATMENT_REQUESTS_LEVEL_ENUM_REQUEST,
  };
};

export const getTreatmentRequestsTypeEnum = () => {
  return {
    type: GET_TREATMENT_REQUESTS_TYPE_ENUM_REQUEST,
  };
};

export const createTreatmentRequest = (info) => {
  return {
    type: CREATE_TREATMENT_REQUESTS_REQUEST,
    payload: info,
  };
};
